import {useLoaderData} from '@remix-run/react';

export function Configuration() {
  const {bugsnagConfig} = useLoaderData<{
    bugsnagConfig: {
      apiKey: string;
      releaseStage: string;
      appVersion: string;
    };
  }>();

  return (
    <>
      <meta name="bugsnag-api-key" content={bugsnagConfig.apiKey} />
      <meta name="bugsnag-release-stage" content={bugsnagConfig.releaseStage} />
      <meta name="bugsnag-app-version" content={bugsnagConfig.appVersion} />
    </>
  );
}
